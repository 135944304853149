<script>
import { HorizontalBar } from "vue-chartjs";
import common from "Mixins/common";
import { MetricCalculations } from "Services/MetricCalculations";

export default {
  extends: HorizontalBar,
  props: {
    vehicle: Object,
  },
  
  created() {
    window.addEventListener("resize", this.drawChart);
  },
  destroyed() {
    window.removeEventListener("resize", this.drawChart);
  },
  methods: {
    drawChart() {
      setTimeout(() => this.renderChart(this.chartdata, this.options), 0);
    },
    getDataPoints() {
      const electricityCostData = MetricCalculations.getElectricityCostData(
        this.vehicle,
        this.location
      );
      const maintCostData = MetricCalculations.getMaintenanceCostData(
        this.vehicle
      );
      const insuranceData = MetricCalculations.getInsuranceCostData(
        this.vehicle,
        this.location
      );

      let evCostToDriveAnnually = Math.round(
        electricityCostData.annualElectricityCost
      );
      let gasCostToDriveAnnually = Math.round(
        electricityCostData.annualGasolineCost
      );
      let evMaintenanceCostAnnually = Math.round(
        maintCostData.evAnnualMaintenanceCost
      );
      let gasMaintenanceCostAnnually = Math.round(
        maintCostData.gasAnnualMaintenanceCost
      );
      let evAnnualInsuranceCost = Math.round(
        insuranceData.evAnnualInsuranceCost
      );
      let gasAnnualInsuranceCost = Math.round(
        insuranceData.gasAnnualInsuranceCost
      );

      return {
        evCostToDriveAnnually,
        gasCostToDriveAnnually,
        totalEv: evCostToDriveAnnually + evMaintenanceCostAnnually,
        totalGas:
          gasCostToDriveAnnually + gasMaintenanceCostAnnually,
        evMaintenanceCostAnnually,
        gasMaintenanceCostAnnually,
        evAnnualInsuranceCost,
        gasAnnualInsuranceCost,
      };
    },
  },
  computed: {
    mobileDevice() {
      return window.outerWidth < 800;
    },
    location() {
      return this.$store.state.locationInfo;
    },
    options() {
      let options = {
        tooltips: {
          filter(tooltipItem) {
            if (tooltipItem.value === "0") return false;
            return true;
          },
        },
        legend: {
          position: this.mobileDevice ? "bottom" : "right",
          align: 'start'
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              beginAtZero: true,
              gridLines: {
                drawBorder: true,
              },
              ticks: {
                callback(value) {
                  return common.toCurrency(value, 0);
                },
              },
            },
          ],
          yAxes: [
            {
              display: true,
              stacked: true,
              ticks: {
                fontFamily: 'FontAwesome',
                fontSize: '20'
              },
            },
          ],
        },
      };
      return options;
    },
    chartdata() {
      let dataPoints = this.getDataPoints();
      let barThickness = this.mobileDevice ? 30 : 40;
      let chartdata = {
        labels: ["\uf1e6", "\uf52f"],
        datasets: [
          {
            label: "Cost of electricity in your area",
            backgroundColor: "#59DA1E",
            data: [dataPoints.evCostToDriveAnnually, 0],
            barThickness: barThickness,
          },
          {
            label: "Cost of gasoline in your area",
            backgroundColor: "black",
            data: [0, dataPoints.gasCostToDriveAnnually],
            barThickness: barThickness,
          },
          {
            label: "Maintenance cost                    ",
            backgroundColor: "#3CCACA",
            data: [
              dataPoints.evMaintenanceCostAnnually,
              dataPoints.gasMaintenanceCostAnnually,
            ],
            barThickness: barThickness,
          },
          {
            label: "Local insurance cost",
            backgroundColor: "darkred",
            data: [
              dataPoints.evAnnualInsuranceCost,
              dataPoints.gasAnnualInsuranceCost,
            ],
            barThickness: barThickness,
          },
        ],
      };
      return chartdata;
    },
  },
  mounted() {
    this.drawChart();
  },
  watch: {
    chartdata() {
      this.drawChart();
    },
    height() {
      this.drawChart();
    },
  },
};
</script>
<style lang="scss" scoped>

</style>